<template>
  <div class="whatsapp-icon d-none">
    <a href="https://wa.me/+96555077182" target="_blank">
      <i class="icomoon icon-whatsapp"></i>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.whatsapp-icon {
  @include position($position: fixed, $top: 75%, $right: 4%, $z-index: 2);

  i {
    color: $white;
    background-color: $dark-green;
    font-size: toRem(45);
    border-radius: 50%;
    padding: 0.9rem;
    box-shadow: 0 toRem(4) toRem(10) 0 $dark5;
  }
}

@media screen and (min-width: 992px) {
  .whatsapp-icon {
    i {
      font-size: toRem(35);
      padding: 0.9rem;
    }
  }
}

@media screen and (min-width: 1900px) {
  .whatsapp-icon {
    i {
      font-size: toRem(45);
    }
  }
}
</style>
